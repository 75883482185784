// EMBED
(() => {
  // Datawrapper responsive scaling
  // https://academy.datawrapper.de/article/180-how-to-embed-charts
  if (window && window.addEventListener) {
    window.addEventListener('message', function _message(a) {
      if (a.data['datawrapper-height'] !== undefined) {
        for (let e in a.data['datawrapper-height']) {
          const t =
            document.getElementById('datawrapper-chart-' + e) ||
            document.querySelector("iframe[src*='" + e + "']");
          if (t) {
            t.style.height = a.data['datawrapper-height'][e] + 'px';
          }
        }
      }
    });
  }
})();
